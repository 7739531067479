import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import logo from "../utils/images/Logo.png";
import Icons from "../utils/icon";
import { useCookies } from 'react-cookie';


const Navbar = ({ isLoggedIn, handleLogout }) => {
  const [cookies] = useCookies(["_user"]);
  const userData = cookies['_user'];
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);

  const handleToggleNav = () => setIsNavOpen(!isNavOpen);
  const handleToggleProfile = () => setToggleProfile(!toggleProfile);

  useEffect(() => {
    if (isNavOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [isNavOpen]);

  const handlePostJobClick = () => {
    if (isLoggedIn) navigate("/postjob");
    else toast.success("Please log in first to post a job");
  };

  return (
    <nav id="nav-header" className="scroll-shadow flex justify-between items-center top-0 py-2 2xl:px-4 sm:p-2 fixed w-full bg-white z-10 font-out font-semibold">
      <div className="flex items-center gap-2 z-10">
        <Link to="/">
          <img src={logo} alt="logo-img" className="w-40 sm:w-32" />
        </Link>
      </div>
      <div className="flex gap-4 items-center md:hidden">
        <Link to="/jobs" className="text-black no-underline m-0 cursor-pointer">
          Find Jobs
        </Link>
        <p className="text-black no-underline m-0 cursor-pointer" onClick={handlePostJobClick}>
          Post a Job
        </p>
        {isLoggedIn ? (
          <div>
            <img
              src={userData.picture}
              alt="profile-img"
              className="w-10 h-10 rounded-full border-2 border-slate-300 ml-1 cursor-pointer"
              onClick={handleToggleProfile}
            />
            {toggleProfile && (
              <div className="bg-white rounded-md absolute right-6 top-20 flex flex-col p-2 animate__animated animate__fadeInDown shadow-md w-64">
                <div className="flex gap-2 items-center mb-2">
                  <img src={userData.picture} alt="profile-img" className="w-10 h-10 rounded-full" />
                  <h1 className="mb-0 text-lg">{userData.username}</h1>
                </div>
                <div className="flex flex-col">
                  <Link to={`/user/${userData.username}`} onClick={handleToggleProfile} className="flex items-center no-underline gap-2 text-gray-500 rounded-md p-2 hover:bg-gray-200 cursor-pointer font-medium">{Icons.profile}Profile</Link>
                  <Link to='/login' onClick={() => { handleLogout(); setToggleProfile(false); }} className="flex items-center no-underline gap-2 text-gray-500 rounded-md p-2 hover:bg-gray-200 cursor-pointer font-medium">{Icons.logout}Logout</Link>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Link to="/login" className="flex items-center gap-2 justify-center no-underline rounded-md bg-blue-500 text-white py-2 px-3 transition duration-400 hover:bg-blue-700">
              {Icons.login}SignUp/SignIn
          </Link>
        )}
      </div>

      {isNavOpen && (
        <div className="absolute right-0 top-0 bg-white h-screen w-full text-center animate__animated animate__slideInDown">
          <div className="flex flex-col justify-center px-4 h-full gap-6">
            {isLoggedIn && (
              <div className="flex flex-col items-center">
                <img src={userData.picture} alt="profile-img" className="w-28 h-28 rounded-full border-4 border-slate-300" />
                <h1 className="text-lg my-2">Welcome {userData.name.split(' ')[0]}</h1>
                <hr className="text-gray-400 w-full" />
              </div>
            )}
            {isLoggedIn && (
              <Link to={`/user/${userData.username}`} onClick={handleToggleNav} className="no-underline rounded-md bg-blue-500 text-white py-[5px] px-5 text-[1.1rem] mb-0 flex items-center gap-2 justify-center">
                {Icons.profile}Profile
              </Link>
            )}
            <Link to="/jobs" className="rounded-md bg-blue-500 text-white py-[5px] px-5 text-[1.1rem] no-underline flex items-center gap-2 justify-center" onClick={handleToggleNav}>
              {Icons.search}Find Jobs
            </Link>
            <p className="rounded-md bg-blue-500 text-white py-[5px] px-5 text-[1.1rem] mb-0 flex items-center gap-2 justify-center" onClick={() => { setIsNavOpen(false); handlePostJobClick();}}>
              {Icons.send}Post a Job
            </p>
            {isLoggedIn ? (
              <Link to="/login" className="rounded-md bg-blue-500 text-white py-[5px] px-5 text-[1.1rem] no-underline flex items-center gap-2 justify-center" onClick={() => { handleLogout(); setIsNavOpen(false); }}>
                {Icons.logout}Logout
              </Link>
            ) : (
              <Link to="/login" className="rounded-md bg-blue-500 text-white py-[5px] px-5 text-[1.1rem] no-underline flex items-center gap-2 justify-center" onClick={handleToggleNav}>
                 {Icons.login}SignUp/SignIn
              </Link>
            )}
          </div>
        </div>
      )}
      <div className="hidden md:flex items-center gap-1">
        <button className="rounded-md bg-blue-500 p-[7px] text-[0.9rem] ">
          <Link to="/jobs" className="no-underline text-white">
            All Jobs
          </Link>
        </button>
        {isNavOpen ? (
          <button className="rounded-md bg-blue-500 text-white p-2.5 z-10" onClick={handleToggleNav}>
            {Icons.cross}
          </button>
        ) : isLoggedIn ? (
          <img
            src={userData.picture}
            alt="profile-img"
            className="w-10 h-10 rounded-full border-2 border-slate-300 ml-1"
            onClick={handleToggleNav}
          />
        ) : (
          <button className="rounded-md bg-blue-500 text-white p-2.5 z-10" onClick={handleToggleNav}>
            {Icons.bar}
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
