import React, { useState } from 'react';
import icons from '../utils/icon';
import timeSince from "../utils/helper/timeago";
import axios from 'axios';
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';

const Comment = ({ comment,isLoggedIn}) => {
    const [loading,setLoading]= useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(comment.likeCount);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [commentReplies, setCommentReplies] = useState([]);
    const [cookies] = useCookies(['_user']);
    const userData= cookies['_user'];

    const fetchReplies = async (jobId,parentCommentId) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getComments?jobId=${jobId}&parentCommentId=${parentCommentId}`);
            const data = response.data;
            setCommentReplies(data.comments);
        } catch (err) {
            if(err.response.status===500) toast.error("Error while fetching replies");
        }finally{
            setLoading(false);
        }
    };

    const toggleReplies = async () =>{
        if(comment.replyCount>0 && commentReplies.length===0){
            await fetchReplies(comment.postId,comment._id);
            setShowReplies(true);
        }
        else setShowReplies(!showReplies);
    }

    const handleReply = () => {
        if(!isLoggedIn) toast.error("Log in first");
        else {
            setShowReplyForm(!showReplyForm);
            setReplyText("");
        }
    };

    const toggleLike = async () => {
        if (!isLoggedIn) {
            toast.error("Log in first");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/updateLikesOnComment`, {
                commentId: comment._id,
                userId: userData._id
            });
            if (response.data.message === 'Comment liked.') {
                setLikes(likes + 1);
                setIsLiked(true);
            } else if (response.data.message === 'Comment unliked.') {
                setLikes(likes - 1);
                setIsLiked(false);
            }
        } catch (err) {
            toast.error("Error while liking/disliking");
        }
    };

    const submitReply = async(e) => {
        e.preventDefault();
        try{
            const data={
             commentData:replyText,
             commentedBy:userData._id,
             postId:comment.postId,
             parentCommentId:comment._id
            }
             const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addComment`,data);
             const res=response.data;
             if(commentReplies.length!==0) setCommentReplies(prevComments => [...prevComments, res.comment]);
             comment.replyCount= comment.replyCount+1;
             setReplyText("");
             setShowReplyForm(!showReplyForm);
             toast.success("reply posted");
          }catch(e){
              toast.error("An error occurred while replying");
          }
        
    };

    return (
        <div className='pl-3 pt-2 pb-2 border-l-2 border-blue-400 mb-2 hover:border-slate-700 animate__animated animate__fadeInDown'>
            <div onClick={toggleReplies} className='cursor-pointer'>
                <div className='flex items-center gap-2'>
                    <img alt='profile-img' className='rounded-full w-10 h-10 sm:w-8 sm:h-8 border-2 border-slate-300' src={comment.commentedBy.picture} />
                    <div className='font-semibold sm:text-sm'>{comment.commentedBy.username}</div>
                    <div className='text-sm text-gray-400'>{timeSince(comment.createdAt)}</div>
                </div>
                <div className='text-gray-600 sm:text-sm mt-3 text-justify sm:mr-1'>{comment.commentData}</div>
            </div>
            <div className='flex gap-3 items-center justify-end mt-2 sm:mr-1'>
                {comment.replyCount > 0 && (
                    <span className='sm:text-sm cursor-pointer underline text-blue-600' onClick={toggleReplies}>{loading ? "Loading..." : (showReplies ? "Hide Replies" : `${comment.replyCount} replies`)}</span>
                )} 
                <span className='flex items-center sm:text-sm cursor-pointer' onClick={toggleLike}>{likes}{isLiked?icons.like:icons.blackheart}</span> 
                <span className='sm:text-sm cursor-pointer' onClick={handleReply}>{icons.reply}</span>
            </div>
            {showReplyForm && (
                <form onSubmit={submitReply} className='mt-2'>
                    <textarea
                        className='w-full p-2 border rounded-md outline-0 sm:text-sm'
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder='Write a reply...'
                        required
                    />
                    <button type='submit' className="bg-blue-500 rounded-md px-4 py-2 font-semibold text-white sm:text-sm shadow-md">Reply</button>
                </form>
            )}
            {showReplies && (
                <div className='mt-2'>
                    {commentReplies.map(reply => (
                        <Comment key={reply._id} comment={reply} isLoggedIn={isLoggedIn}/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Comment;
