import React from 'react';
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import toast from "react-hot-toast";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import axios from 'axios';


const handleGoogleLogin = async (userCredential, setCookie, setIsLoggedIn, navigate) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/auth/google`, { credential: userCredential });
      const res=response.data;
      if (res.status) {
        const expiryTimeInSeconds = 7 * 24 * 60 * 60; // 7 days in seconds
        setCookie('_user', res.user, { maxAge: expiryTimeInSeconds });
        setIsLoggedIn(true);
        navigate("/");
        toast.success(res.message);
      }
    } catch (error) {
        toast.error("An error occurred while signing you in, try again after sometime");
    }
};

const GoogleAuth = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies(['_user']);

  return (
    <>
      <GoogleLogin
        onSuccess={credentialResponse => {
          handleGoogleLogin(credentialResponse.credential,setCookie,setIsLoggedIn,navigate)
        }}
        onError={() => {
            toast.error("An error occurred while signing you in, try again after sometime");
        }}
      />
    </>
  );
};

const GoogleOneTapAuth = ({ setIsLoggedIn }) => {
  const [, setCookie] = useCookies(["_user"]);
  const navigate = useNavigate();

  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
        handleGoogleLogin(credentialResponse.credential,setCookie,setIsLoggedIn,navigate)
    },
    onError: () => {
        toast.error("An error occurred while signing you in, try again after sometime");
    }
  });

  return null;
};

export { GoogleAuth, GoogleOneTapAuth };
