import React from 'react';
import notfound from '../utils/images/notfound.png'

const Notfound = () => {
  return (
    <div className="font-free flex flex-col items-center justify-center h-screen p-10 text-center">
        <img src={notfound} alt="Sad Emoji" className="w-52 sm:w-36" />
        <h1 className="text-3xl font-bold text-gray-800 mb-2 sm:text-xl">404 - Not Found</h1>
        <p className="text-md text-gray-600 sm:text-[0.7rem]">Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default Notfound;
