import slugify from 'slugify'; 
import banner1 from '../images/banners/banner1.jpg'
import banner2 from '../images/banners/banner2.jpg'
import banner3 from '../images/banners/banner3.png'
import banner4 from '../images/banners/banner4.jpg'
import banner5 from '../images/banners/banner5.jpg'
import banner6 from '../images/banners/banner6.jpg'
import banner7 from '../images/banners/banner7.jpg'
import banner8 from '../images/banners/banner8.jpg'
import banner9 from '../images/banners/banner9.jpg'
import banner10 from '../images/banners/banner10.jpg'


const bannerImageArray = [
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    banner7,
    banner8,
    banner9,
    banner10
  ];

const generateSlug=(jobTitle)=>{
  const Title= String(jobTitle);
  return slugify(Title, {lower: true,remove: /[*+~.()'"!:@]/g});
}

export { bannerImageArray, generateSlug };