import React, { useState } from "react";
import { Link } from "react-router-dom";
import log from "../utils/images/log.gif";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { GoogleAuth } from "../components/Auth";
import ForgotPassModal from "../components/modals/ForgotPassModal";

const Login = ({isLoggedIn,setIsLoggedIn}) => {
  
  const navigate = useNavigate();
  const [,setCookie] = useCookies(['_user']);
  const [formData, setFormData] = useState({
    emailOrUsername: "",
    password: "",
  });
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, formData);
      const responseData = res.data;
      if (responseData.status) {
        const expiryTimeInSeconds = 7 * 24 * 60 * 60; // 7 days in seconds

        if(responseData.user.picture === null){
          responseData.user.picture =  `https://api.multiavatar.com/${responseData.user.email }.png?apikey=dOeXIxY8aCkV1A`;
        }
        setCookie('_user', responseData.user, { maxAge: expiryTimeInSeconds });
        setIsLoggedIn(true);
        navigate("/");
        toast.success(responseData.message);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.status === false) {
        toast.error("Username/Email or Password is incorrect");
      }
    }
  };
  
  return (
    <>
      {isLoggedIn?(
        navigate('/')
      ):(
        <div className="font-out flex items-center justify-center mt-24 md:px-3 sm:py-5 mb-20">
          <div className="bg-gray-800 flex sm:block rounded-2xl shadow-md justify-between p-3 items-center max-w-3xl sm:w-full animate__animated animate__zoomIn">
            <div className="px-8 sm:px-4 md:py-5">
              <h2 className="font-bold text-4xl text-blue-500 sm:text-3xl">Login</h2>
              <p className="text-sm mt-2 text-blue-600 font-semibold sm:text-xs">
                If you already have an account, log in here
              </p>

              <form onSubmit={handleSubmit} className="flex flex-col gap-4 border-b-2 mb-4 border-gray-300">
                <input
                  className="p-2 mt-8 rounded-lg outline-0 border text-md sm:text-sm"
                  type="text"
                  name="emailOrUsername"
                  value={formData.emailOrUsername}
                  onChange={handleChange}
                  placeholder="Username/Email"
                  required
                />
                <input
                  className="p-2 rounded-lg border outline-0 text-md sm:text-sm"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
                <button type="submit" className="bg-blue-500 rounded-lg text-white py-2 hover:scale-105 duration-300 sm:text-sm">Login</button>
                <div>
                  <p className="font-semibold text-sm text-blue-600 sm:text-xs text-center mx-10 sm:mx-5 mb-1">
                    Don't have an account?<Link to="/register" className="text-blue-400 ml-4">Register</Link>
                  </p>
                  <p className="text-red-500 text-center font-semibold text-sm sm:text-xs underline cursor-pointer" onClick={() => setIsModalOpen(true)} >Forgot Password</p>
                </div>
              </form>

              <div className="flex justify-center">
                <GoogleAuth setIsLoggedIn={setIsLoggedIn}/>
              </div>

            </div>

            <div className="w-1/2 md:hidden">
              <img className="rounded-2xl" alt="login" src={log} />
            </div>
          </div>
        </div>
      )}
      <ForgotPassModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
    </>

  );
};

export default Login;
