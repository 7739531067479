import React, { useEffect, useState } from "react";
import icons from '../utils/icon'
import {bannerImageArray,generateSlug} from '../utils/helper/banner'
import toast from "react-hot-toast";
import Comment from "../components/Comment";
import { useCookies } from 'react-cookie';
import Loader from '../components/Loader'
import { useParams } from "react-router-dom";
import axios from "axios";
import Notfound from '../Pages/Notfound'


const Discussion = ({isLoggedIn}) => {
  const [isJobSaved, setIsJobSaved] = useState(false);
  const [cookies] = useCookies(['_user']);
  const userData= cookies['_user'];
  const {jobId,slug} = useParams();
  const [randomBanner, setRandomBanner] = useState("");
  const [jobData, setJobData] = useState({});
  const [jobComments, setjobComments] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("No Discussion started Yet!");
  const [validurl,setvalidurl]= useState(true);
   
  useEffect(() => {
    const fetchJob = async () => {
      try {
        if(isNaN(jobId)){
          setvalidurl(false);
          return;
        }
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/jobs/getjob/${jobId}`);
        setJobData(res.data);
        if(slug!==generateSlug(res.data.jobTitle)) setvalidurl(false);
      } catch (e) {
        if(e.response && e.response.data){
          // toast.error(e.response.data.message);
          setvalidurl(false);
        }
      }
    };

    const checkIfJobSaved = () => {
      const existingJobs = JSON.parse(localStorage.getItem('savedJobs'));
      if(existingJobs!==null){
         const JobFound= existingJobs.filter(item=> String(item.jobID)===jobId);
         if(JobFound.length!==0) setIsJobSaved(true);
      }
    };

    const randomImage = bannerImageArray[Math.floor(Math.random() * bannerImageArray.length)];
    setRandomBanner(randomImage);
    fetchJob();
    checkIfJobSaved();
  }, [jobId]); 

  useEffect(() => {
    const fetchComments = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getComments?jobId=${jobData._id}`);
          setjobComments(res.data.comments);
        } catch (e) {
           if(e.response && e.response.status===500){
            toast.error("Error while fetching comments");
            setError("Error while fetching comments");
           }
        }finally{
          setLoading(false);
        }
    };

    if(Object.keys(jobData).length !== 0) fetchComments();
  }, [jobData]);

  const handlePostComment= async(e)=>{
     e.preventDefault();
     try{
       if(!isLoggedIn ){
          toast.error("Login to post a comment");
          setComment("");
          return;
       } 
       const data={
        commentData:comment,
        commentedBy:userData._id,
        postId:jobData._id,
       }
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/addComment`,data);
        const res=response.data;
        setjobComments(prevComments => [...prevComments, res.comment]);
        setComment("");
        toast.success("comment posted");
     }catch(e){
         toast.error("An error occurred while posting comment");
     }
  }

  const addToLocalStorage=()=>{
    if(!isLoggedIn){
      toast.error("login to save this Job");
      return;
    }
    const existingJobs = JSON.parse(localStorage.getItem('savedJobs')) || [];
    existingJobs.push(jobData);
    localStorage.setItem('savedJobs', JSON.stringify(existingJobs));
    setIsJobSaved(true);
    toast.success("Job Saved");
 }


  return (
    <>
      {validurl?(
        <div className="font-out mt-24 px-20 md:px-2 mb-4 ">
        {Object.keys(jobData).length === 0?(
          <div className="flex items-center justify-center py-72 sm:py-[280px]">
            <Loader/>
          </div>
        ):(
          <div className='flex flex-col gap-4 animate__animated animate__zoomIn'>
            <div className='relative flex justify-between rounded-lg p-4 md:flex-col md:gap-4 shadow-md' style={{backgroundImage: `url("${randomBanner}")`,backgroundSize: "cover",backgroundRepeat: "no-repeat",}}>
            <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>
              <div className='flex gap-4 items-center z-10'>
                <img src={jobData.image} alt="company-logo" className="w-32 h-32 rounded-md border-4 border-slate-300 bg-white md:w-24 md:h-24 sm:w-[70px] sm:h-[70px] "/>
                <div className='flex flex-col gap-[15px] md:gap-1 sm:gap-0'>
                    <h1 className='text-xl mb-0 md:text-lg sm:text-[1rem] text-white'>{window.innerWidth < 450 ? jobData.companyName?.slice(0, 25) : jobData.companyName?.slice(0, 65)}</h1>
                    <h1 className='text-3xl mb-0 md:text-2xl sm:text-xl text-white'>{window.innerWidth < 450 ? jobData.jobTitle?.slice(0, 20) : jobData.jobTitle?.slice(0, 50)}</h1>
                    <h1 className='text-xl mb-0 md:text-lg sm:text-[1rem] text-white'>{jobData.jobType}</h1>
                </div>
              </div>
              <div className='flex flex-col gap-2 items-center justify-center z-10'>
                <button className='bg-blue-500 rounded-md px-4 py-2 w-full font-semibold text-white hover:bg-blue-700 sm:text-sm'>
                  <a href={jobData.jobLink} target="_blank" className="no-underline text-inherit">Apply Job</a>
                </button>
                {isJobSaved && isLoggedIn?(
                  <button className='flex items-center justify-center gap-2 bg-green-700 text-white rounded-md px-4 py-2 w-full font-semibold sm:text-sm'>{icons.saved} Saved</button>
                ):(
                  <button className='flex items-center justify-center gap-2 bg-slate-700 text-white rounded-md px-4 py-2 w-full font-semibold hover:bg-black hover:text-white sm:text-sm' onClick={addToLocalStorage}>{icons.save} Save Job</button>
                )}
            
              </div>
            </div>
            <div>
                <h2 className='text-2xl sm:text-xl'>Job Discussion</h2>
                <form className='flex gap-2 sm:flex-col' onSubmit={handlePostComment}>
                  <input
                    type='text'
                    placeholder='Join the discussion!'
                    className='rounded-md p-2 w-full border-2 border-gray-200 focus:border-blue-300 outline-0 sm:text-sm'
                    value={comment}
                    onChange={(e)=>setComment(e.target.value)}
                    required
                  />
                  <button type='submit' className='bg-blue-500 rounded-md px-4 py-2 font-semibold text-white hover:bg-blue-700 sm:text-sm shadow-md' >Comment</button>
                </form>
                {/* filter options */}
                <div className='my-3'>
                  <select className='bg-slate-700 p-2 w-56 sm:w-36 rounded-md sm:text-sm outline-0 text-white'>
                    <option value="">Filter</option>
                    <option value="Top">Top</option>
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                  </select>
                </div>
                {/* comment section */}
                <div>
                {loading ? (
                    <div className='flex items-center justify-center bg-slate-200 rounded-md h-72'><Loader /></div>
                  ) : jobComments.length === 0 ? (
                    <div className='flex items-center justify-center bg-slate-200 font-semibold rounded-md h-72'>{error}</div>
                  ) : (
                    <div>
                      {jobComments.map(comment => (
                        <Comment key={comment._id} comment={comment} isLoggedIn={isLoggedIn} />
                      ))}
                    </div>
                  )}
                </div>
            </div>
          </div>
        )}     
        </div>
      ):(
        <Notfound/>
      )}
    </>
  );
}

export default Discussion