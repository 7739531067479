import React from 'react'
import { IoMdArrowRoundUp } from "react-icons/io";

const Scrolltotop = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
        <div id="scrollup" className="flex items-center fixed bg-black right-2 bottom-[-20%] rounded-md p-2 z-10">
        <button onClick={handleScroll}>
          <IoMdArrowRoundUp fontSize={20} className="text-white"/>
          </button>
      </div>
    </>
  )
}

export default Scrolltotop