import React, { useState,useCallback } from "react";
import { Link } from "react-router-dom";
import log from "../utils/images/log.gif";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { GoogleAuth } from "../components/Auth";
import { debounce } from 'lodash';


const Register = ({isLoggedIn,setIsLoggedIn}) => {
  const [usernameAvailability, setUsernameAvailability] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    username:"",
    email: "",
    password: "",
  });

  const checkUsername = useCallback(
    debounce(async (username) => {
      try{
        const res= await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/checkUsernameAvailability?username=${username}`);
        if(res.data.status) setUsernameAvailability(res.data.message);
     }catch(e){
        if(e.response && e.response.status!==500 && e.response.data && e.response.data.status === false){
           setUsernameAvailability(e.response.data.message);
        }
     }
    }, 500),
    [] 
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'username') {
      if (value === "" || value.trim()==="") {
        setUsernameAvailability("");
        checkUsername.cancel(); 
      } else {
        checkUsername(value);
      }
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/register`, formData);
      const responseData = res.data;
      if (responseData.status) {
        navigate('/login');
        toast.success(responseData.message);
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      toast.error("An error occurred while creating user, please try again");
    }
  };
  

  return (
    <>
      {isLoggedIn?(
         navigate('/')
      ):(
        <div className="font-out flex items-center justify-center mt-24 md:px-3 sm:py-5 mb-20">
      <div className="bg-gray-800 flex sm:block rounded-xl shadow-md justify-between p-3 items-center max-w-3xl sm:w-full animate__animated animate__zoomIn">
        <div className="px-8 sm:px-4 md:py-5">
          <h2 className="font-bold text-4xl text-blue-500 sm:text-3xl">Register</h2>
          <p className="text-sm mt-2 text-blue-600 font-semibold sm:text-xs">
            Don't have an account, register here
          </p>

          <form onSubmit={handleSubmit} className="flex flex-col gap-3 border-b-2 mb-4 border-gray-300">
            <input
              className="p-2 mt-4 rounded-lg outline-0 border text-md sm:text-sm"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              required
            />
            <div className="w-full">
              <input
                className="p-2 rounded-lg outline-0 border text-md sm:text-sm w-full"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Username"
                required
              />
              <p className={`mb-0 text-sm ml-1 text-wrap ${usernameAvailability==="Username is available" ? 'text-green-400' : 'text-red-400'}`}>{usernameAvailability}</p>
            </div>
            
            <input
              className="p-2 rounded-lg outline-0 border text-md sm:text-sm"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
            <input
              className="p-2 rounded-lg border outline-0 text-md sm:text-sm"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <button type="submit" className="bg-blue-500 rounded-lg text-white py-2 hover:scale-105 duration-300 sm:text-sm">Register</button>
            <div>
              <p className="font-semibold text-sm text-blue-600 mb-2 sm:text-xs text-center mx-10 sm:mx-5">
                Already have an account?<Link to="/login" className="text-blue-400 ml-4">Login</Link>
              </p>
            </div>

          </form>

          <div className="flex justify-center">
            <GoogleAuth setIsLoggedIn={setIsLoggedIn}/>
          </div>
          
        </div>

        <div className="w-1/2 md:hidden">
          <img className="rounded-xl" alt="login" src={log} />
        </div>
      </div>
    </div>
      )}
    </>
  );
};

export default Register;
