import React from 'react';
import { IoSearchOutline } from "react-icons/io5";
import { FaChrome } from "react-icons/fa";
import { FaFirefox } from "react-icons/fa";
import { FaSafari } from "react-icons/fa";
import { FaEdge } from "react-icons/fa";
import { FaOpera } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { GoShieldCheck } from "react-icons/go";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { CgSandClock } from "react-icons/cg";
import { LuCopyright } from "react-icons/lu";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";
import { FaTelegram } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FcLike } from "react-icons/fc";
import { FaReply } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { GoHeart } from "react-icons/go";
import { GoCommentDiscussion } from "react-icons/go";
import { FiUser } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { TbLogin2 } from "react-icons/tb";
import { FaBookmark } from "react-icons/fa";


const Icons = {
  search: <IoSearchOutline />,
  chrome: <FaChrome/>,
  firefox: <FaFirefox/>,
  safari: <FaSafari/>,
  edge: <FaEdge/>,
  opera: <FaOpera/>,
  eye: <FaRegEyeSlash/>,
  shield: <GoShieldCheck/>,
  check: <IoMdCheckmarkCircleOutline/>,
  time: <CgSandClock/>,
  copyright: <LuCopyright/>,
  linkedin: <FaLinkedin/>,
  whatsapp: <FaWhatsappSquare/>,
  telegram: <FaTelegram/>,
  send: <SiMinutemailer/>,
  mail: <MdOutlineMail/>,
  save: <FaRegBookmark/>,
  saved:<FaBookmark/>,
  like:<FcLike/>,
  reply:<FaReply/>,
  logout:<TbLogout2/>,
  login:<TbLogin2/>,
  blackheart:<GoHeart/>,
  discuss:<GoCommentDiscussion/>,
  profile:<FiUser/>,
  bar:<FaBars/>,
  cross:<MdOutlineClose/>
};

export default Icons;
